import { createGlobalStyle } from 'styled-components';
import Variables, { Colors } from './Variables';
import Functions from './Functions';

const Global = createGlobalStyle`
* {
	box-sizing: border-box;
}
html {
	overflow-x: hidden;
	overflow-y: scroll;
	overflow-y: overlay;
	margin-left: 0;
    margin-right: 0;
	max-width: 100%;

	scrollbar-width: thin;
	::-webkit-scrollbar {
  		width:0.6em;
	}
	::-webkit-scrollbar-track {
		background: transparent;

		&:hover {
				background: ${Colors.defaultOffWhite};
				background-clip: padding-box;
	
				border: 1px solid transparent;
				border-radius: 1em;
			}

	}
	::-webkit-scrollbar-thumb {
 		background-color: rgba(0,0,0,0.5);
		border-radius: 1em;
	}
}

body {
	background-color: var(--background);
	color: var(--text);
	font-family: 'Söhne', Tahoma, Helvetica, Arial, Roboto, sans-serif;
	font-size: 16px;
	transition: color ${Colors.transition}s, background-color ${Colors.transition}s;
	-webkit-font-smoothing: antialiased;
	font-smooth: always;
	-moz-osx-font-smoothing: auto;
	max-width: 100%;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
input:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* oldIE 😉 */

a {
	color: var(--text);
}

.app-content {
	min-height:  100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
}

button {
	border: none;
	background-color: inherit;
	padding: 0;
	:hover {
		cursor: pointer;
	}
}

.fade-enter {
  opacity: 0;
	transform: translateX(500px);
	position: absolute;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
	transform: translateX(0px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-out;
}

.fade-exit {
  opacity: 1;
	transform: translateX(0px);
}

.fade-exit-active {
  opacity: 0;
	transform: translateX(-500px);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

p {
	margin: 0px;
}

:root {
	--gridMargin: ${Variables.gridMarginMobile};
	--gridGutter: ${Variables.gridGutterMobile};
	--textModuleGap: 4rem;
	--contentModuleGap: 12rem;
	--background: ${Colors.defaultOffWhite};
	--backgroundOff: ${Colors.defaultLightGrey};
	--graphic: ${Colors.defaultLightGrey};
	--contrastText: ${Colors.defaultLightGrey};
	--harsh: ${Colors.defaultWhite};
	--border: ${Colors.defaultDarkGrey};
}

${Functions.breakpoint('mobile')} {
	:root {
		--gridMargin: ${Variables.gridMargin};
		--gridGutter: ${Variables.gridGutter};
		--background: ${Colors.defaultLightGrey};
	}
}

${Functions.breakpoint('desktop')} {
	:root {
		--gridMargin: ${Variables.gridMarginUltra};
		--gridGutter: ${Variables.gridGutterUltra};
	}
}
`;

export default Global;
